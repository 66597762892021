export function run() {
    $(function () {
        var gallery = $('#marketing-support.product .gallery');
        var gallery_items_ct = gallery.find('.thumbs > div > div ul li').length;
        var select_active_item = '';
        $('#marketing-support.product .description select[name="property-' + gallery.find('.thumbs li.active').data('property-id') + '"]').val(gallery.find('.thumbs li.active').data('ms-id'));

        $('#marketing-support.product select.document').each(function(){
            changeDownloadHref($(this));
        });

        gallery.on('click', '.thumbs li', function(){
            if($(this).hasClass('disabled') || $(this).data('ms-id') == select_active_item){
                return;
            }

            select_active_item = $(this).data('ms-id');

            gallery.find('.thumbs li').addClass('disabled');
            gallery.find('.viewer video').get(0).pause();
            gallery.find('.viewer video').hide();
            gallery.find('.viewer .video').show();

            var document_select = $('#marketing-support.product .description select[name="property-' + $(this).data('property-id') + '"]');
            document_select.val($(this).data('ms-id'));
            if(document_select.hasClass('sub-property-select')){
                $('#marketing-support.product .sub-property').addClass('d-none');
                $('#marketing-support.product #sub-property-' + $(this).data('property-id')).removeClass('d-none');

                $('#marketing-support.product .description select[name="property-' + document_select.data('main-property-id') + '"]').val($(this).data('property-id'));
            }else if(document_select.hasClass('main-property-select')){
                $('#marketing-support.product div.main-property').addClass('d-none');
                $('#marketing-support.product #main-property-' + document_select.data('main-property-id')).removeClass('d-none');

                $('#marketing-support.product .description select[name="product-' + document_select.data('product-id') + '"]').val(document_select.data('main-property-id'));
            }

            changeDownloadHref($('#marketing-support.product .description select[name="property-' + $(this).data('property-id') + '"]'));
            gallery.find('.thumbs li').removeClass('active');
            $(this).addClass('active');
            gallery.find('.viewer > div').append('<img src="'+ $(this).data('image') +'" style="display:none;">');

            if(!$.inArray($(this).data('image-mime-type'), ['video/mp4', 'video/webm', 'video/ogg'])){
                gallery.find('.viewer > div').addClass('video');
                gallery.find('.viewer > div').attr('data-ms-id', $(this).data('ms-id'));
            }else{
                gallery.find('.viewer > div').removeClass('video');
                gallery.find('.viewer > div').removeAttr('data-ms-id');
            }
            gallery.find('.viewer img:first-child').fadeOut(300, function(){$(this).remove(); gallery.find('.thumbs li').removeClass('disabled');});
            gallery.find('.viewer img:last-child').fadeIn(300);

            gallery.find('.thumbs > div > div').animate({scrollLeft: ((($(this).index() - 1) * 74) - 14)}, 300);
        });

        gallery.on('click', '.viewer .video', function(){
            if($(this).attr('data-video-path')){
                gallery.find('.viewer .video').hide();
                gallery.find('.viewer video source').attr('src', $(this).attr('data-video-path')).attr('type', $(this).attr('data-video-type')).show();
                gallery.find('.viewer video').show();
                gallery.find('.viewer video').get(0).play();
            }
        });

        gallery.on('click', '.thumbs .left, .thumbs .right', function(){
            var index = gallery.find('.thumbs > div > div li.active').index();
            if($(this).hasClass('left')){
                index -= 1;
            }else if($(this).hasClass('right')){
                index += 1;
            }


            if(index < 0 ) { index = gallery_items_ct - 1}
            if(index >= gallery_items_ct ) { index = 0}

            gallery.find('.thumbs > div > div li').eq(index).trigger('click');
        });

        $('#marketing-support.product').on('click change', 'select.document', function(){
            gallery.find('.thumbs li[data-ms-id="' + $(this).val() + '"]').trigger('click');
            var index = gallery.find('.thumbs li[data-ms-id="' + $(this).val() + '"]').index();
            gallery.find('.thumbs > div > div').animate({scrollLeft: (index * 74)}, 300);
            changeDownloadHref($(this));
        });

        $('#marketing-support.product').on('click change', 'select.main-property', function(){
            $('#marketing-support.product .sub-property').addClass('d-none');
            $('#marketing-support.product #sub-property-' + $(this).val()).removeClass('d-none');

            var ms_id = $('#marketing-support.product select[name="property-' + $(this).val() + '"]');

            gallery.find('.thumbs li[data-ms-id="' + ms_id.val() + '"]').trigger('click');
            var index = gallery.find('.thumbs li[data-ms-id="' + ms_id.val() + '"]').index();
            gallery.find('.thumbs > div > div').animate({scrollLeft: (index * 74)}, 300);

            changeDownloadHref(ms_id);
        });

        $('#marketing-support.product').on('click change', 'select.product-property', function(){
            $('#marketing-support.product div.main-property').addClass('d-none');
            $('#marketing-support.product #main-property-' + $(this).val()).removeClass('d-none');

            var ms_id = $('#marketing-support.product #main-property-' + $(this).val() + ' select.document:first-child');

            gallery.find('.thumbs li[data-ms-id="' + ms_id.val() + '"]').trigger('click');
            var index = gallery.find('.thumbs li[data-ms-id="' + ms_id.val() + '"]').index();
            gallery.find('.thumbs > div > div').animate({scrollLeft: (index * 74)}, 300);

            changeDownloadHref(ms_id);
        });

        function changeDownloadHref($this){
            if($this.parents('.form-group').find('a').length > 0){
                var href = $this.parents('.form-group').find('a').attr('href').split('?');
                $this.parents('.form-group').find('a').attr('href', href[0] + '?code=' + $this.val());
            }
        }
    });
}
